import React from 'react'
import {Deutsch, English, Type, LinkABOUT,LinkHOME, LinkWORK, Page, Back_To_All_Projects} from '../../components/linksetc.js'
import Layout from '../../components/layout'

import img1 from "../../images/devilstamp/1.jpg"
import img2 from "../../images/devilstamp/2.jpg"
import img3 from "../../images/devilstamp/3.jpg"
import img0 from "../../images/devilstamp/0.jpg"



// for the scrolldown
var Scroll  = require('react-scroll');
var ScrollLink       = Scroll.Link;
var Element    = Scroll.Element;



export default ({ location }) => (
  <Layout>


      <Page location={location}>




      <div className="project_description">

        <English Language={location.search}>
              <Type>
                    The Swiss Postal Service wanted a comic about Switzerland to put on a post stamp.
                    <p/>
                    <a href="http://www.iamsafu.ch" target="_blank">Sarah Furrer (illustrator)</a> and <LinkABOUT location={location}>I</LinkABOUT> made a deal with the devil to create this comic that combines Swiss history and mythology.
                    <p/><p/><p/>
                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>

              </Type>
        </English>


       <Deutsch Language={location.search}>
              <Type>
                    Für eine Briefmarke wollte die schweizerische Post einen Comic zum Thema Schweiz.
                    <p/>
                    <a href="http://www.iamsafu.ch" target="_blank">Sarah Furrer (Illustration)</a> und <LinkABOUT location={location}>Ich</LinkABOUT> schlossen einen Pakt mit dem Teufel um diesen Comic zu erschaffen, der Schweizer Mythologie und Geschichte kombiniert.
                    <p/><p/><p/>
                    <ScrollLink className="ScrollLink" to="test1" smooth={true} duration={1000}>↓</ScrollLink>

              </Type>
        </Deutsch>

    </div>

    <Element name="test1"></Element>


    <img src={img0}/>





      <div className="comment">
        <English Language={location.search}>
              <Type>

              1857 bestieg Edward Whymper als erster Mensch das Matterhorn, indem er sich bei den letzten Metern mit seinem Messer von der Seilschaft losschnitt und an den Bergführern vorbei auf den Gipfel stürmte.
              <p/>
              Nur wenigen ist allerdings bekannt, dass er diesen Moment auf der Stelle schriftlich festhielt. Wem dies etwas seltsam anmutet sollte sich vergegenwärtigen, wie viele Menschen heute unzählige, vergleichsweise völlig bedeutungslose Momente mit ihren Smartphones festhalten. Die Erstbesteigung des Matterhorns hingegen war nicht nur historisch bedeutsam. Für Edward Whymper persönlich war es die Rechtfertigung für ein jahrelanges Unterfangen und zahlreichen Fehlversuchen, bei einem von denen er 60 Meter abstürzte.
              <p/>
              Edward Whymper hielt den Moment seiner Erstbesteigung also in einem Brief fest, den er umgehend an seine Geliebte schicken wollte, die sich zurzeit an einem Kurort in Maloja aufhielt. Warum er glaubte, dass dies möglich sei, ist genauso rätselhaft warum er glaubte, dass er das Matterhorn besteigen könnte.
              <p/>
              Zu Edwards Glück war an jenem Tage aber der Pilatusdrache unterwegs, der sein heimisches
              Luzern verlassen hatte, um für seine Freundin, die Zermatt-Brieftaube, einzuspringen. Als der
              Pilatusdrache so über dem Matterhorn seine Kreise zog, erspähte er Edward Whymper, der mit einem Brief in der Luft winkte, und ihm diesen mit aller Selbstverständlichkeit in die Schnauze legte.
              <p/>
              «He Brieftaube! Dieser Brief muss nach Maloja!»
              <p/>

              </Type>
        </English>


       <Deutsch Language={location.search}>
              <Type>

              1857 bestieg Edward Whymper als erster Mensch das Matterhorn, indem er sich bei den letzten Metern mit seinem Messer von der Seilschaft losschnitt und an den Bergführern vorbei auf den Gipfel stürmte.
              <p/>
              Nur wenigen ist allerdings bekannt, dass er diesen Moment auf der Stelle schriftlich festhielt. Wem dies etwas seltsam anmutet sollte sich vergegenwärtigen, wie viele Menschen heute unzählige, vergleichsweise völlig bedeutungslose Momente mit ihren Smartphones festhalten. Die Erstbesteigung des Matterhorns hingegen war nicht nur historisch bedeutsam. Für Edward Whymper persönlich war es die Rechtfertigung für ein jahrelanges Unterfangen und zahlreichen Fehlversuchen, bei einem von denen er 60 Meter abstürzte.
              <p/>
              Edward Whymper hielt den Moment seiner Erstbesteigung also in einem Brief fest, den er umgehend an seine Geliebte schicken wollte, die sich zurzeit an einem Kurort in Maloja aufhielt. Warum er glaubte, dass dies möglich sei, ist genauso rätselhaft warum er glaubte, dass er das Matterhorn besteigen könnte.
              <p/>
              Zu Edwards Glück war an jenem Tage aber der Pilatusdrache unterwegs, der sein heimisches
              Luzern verlassen hatte, um für seine Freundin, die Zermatt-Brieftaube, einzuspringen. Als der
              Pilatusdrache so über dem Matterhorn seine Kreise zog, erspähte er Edward Whymper, der mit einem Brief in der Luft winkte, und ihm diesen mit aller Selbstverständlichkeit in die Schnauze legte.
              <p/>
              «He Brieftaube! Dieser Brief muss nach Maloja!»
              <p/>

              </Type>
        </Deutsch>


      </div>


      <img src={img1}/>







      <div className="comment">
        <English Language={location.search}>
              <Type>
            
              Der Drache nahm dies als Bestätigung, dass er seine Rolle als Brieftaube völlig perfekt und glaubwürdig spielte. So flog er also mit dem Brief in der Schnauze über die Berge nach Brig, und anschliessend durch das Tal von Goms bis nach Göschenen, in die Schöllenenschlucht, wo er den Brief dem Teufel übergab, der an diesem Tage auch gerade Dienst hatte.

              <p/>«He Teufel! Dieser Brief soll nach Maloja.»
              <p/>«Wo ist denn die Brieftaube?» «Krank. Ich bin eingesprungen.»
              <p/>«Aber der Brief ist ja gar nicht frankiert! Jeder Brief muss mit einer Seele oder einer Briefmarke frankiert sein.»

              <p/>Der Drache hatte von Briefmarken oder dergleichen keine Ahnung, und dachte, dass der Teufel wiedermal einen hinterlistigen Pakt schliessen wollte.               
              
              </Type>
        </English>



       <Deutsch Language={location.search}>
              <Type>

                      Der Drache nahm dies als Bestätigung, dass er seine Rolle als Brieftaube völlig perfekt und glaubwürdig spielte. So flog er also mit dem Brief in der Schnauze über die Berge nach Brig, und anschliessend durch das Tal von Goms bis nach Göschenen, in die Schöllenenschlucht, wo er den Brief dem Teufel übergab, der an diesem Tage auch gerade Dienst hatte.

                      <p/>«He Teufel! Dieser Brief soll nach Maloja.»
                      <p/>«Wo ist denn die Brieftaube?» «Krank. Ich bin eingesprungen.»
                      <p/>«Aber der Brief ist ja gar nicht frankiert! Jeder Brief muss mit einer Seele oder einer Briefmarke frankiert sein.»

                      <p/>Der Drache hatte von Briefmarken oder dergleichen keine Ahnung, und dachte, dass der Teufel wiedermal einen hinterlistigen Pakt schliessen wollte.     

              </Type>
        </Deutsch>

      </div>

      <img src={img2}/>








      <div className="comment">
        <English Language={location.search}>
              <Type>
              <p/>«Hör mal Teufel, ich habe heute keine Zeit für deinen Seich! Ich helfe meiner Freundin, der Zermatt-Brieftaube, aus. Wenn dieser Brief nicht ankommt, fresse ich dich mit Huf und Bärtchen!»

              <p/>Dem Teufel passte das gar nicht, aber schliesslich hatte er besseres zu tun, als sich mit dem
              Pilatusdrachen anzulegen. Also nahm er eine der vielen Seelen aus seinem Vorrat, knallte sie auf den Brief, und übergab diesen der Postkutsche nach Göschenen.

              <p/>Teufel: «Frankiert mit einer Seele.»
              <p/>Kutscher: «Marke! Nicht Seele! Immer dasselbe mit dir!»


              <p/>Der Kutscher schüttelte den Kopf, aber schliesslich wollte er sich nicht mit dem Teufel anlegen und nahm den Brief. Nun legte der Brief einen weiten Weg zurück, denn der Gotthardtunnel war zwar schon angedacht, würde aber erst 23 Jahre später (1880) durchbrochen werden. Nach langen Fahrten und ausgewechselten Pferden kam die Kutsche schliesslich in Maloja an, und der Kutscher übergab den Brief der Geliebten Edwards.

              <p/>«Warum dauerte denn das so lange?», wunderte sich diese.
              <p/>«Wir haben ein paar neue Mitarbeiter, der Pilatus-drache und der Teufel. Die machen immer Ärger, aber sie zu entlassen getraut sich niemand.»

              </Type>
        </English>


       <Deutsch Language={location.search}>
              <Type>

              <p/>«Hör mal Teufel, ich habe heute keine Zeit für deinen Seich! Ich helfe meiner Freundin, der Zermatt-Brieftaube, aus. Wenn dieser Brief nicht ankommt, fresse ich dich mit Huf und Bärtchen!»

              <p/>Dem Teufel passte das gar nicht, aber schliesslich hatte er besseres zu tun, als sich mit dem
              Pilatusdrachen anzulegen. Also nahm er eine der vielen Seelen aus seinem Vorrat, knallte sie auf den Brief, und übergab diesen der Postkutsche nach Göschenen.

              <p/>Teufel: «Frankiert mit einer Seele.»
              <p/>Kutscher: «Marke! Nicht Seele! Immer dasselbe mit dir!»


              <p/>Der Kutscher schüttelte den Kopf, aber schliesslich wollte er sich nicht mit dem Teufel anlegen und nahm den Brief. Nun legte der Brief einen weiten Weg zurück, denn der Gotthardtunnel war zwar schon angedacht, würde aber erst 23 Jahre später (1880) durchbrochen werden. Nach langen Fahrten und ausgewechselten Pferden kam die Kutsche schliesslich in Maloja an, und der Kutscher übergab den Brief der Geliebten Edwards.

              <p/>«Warum dauerte denn das so lange?», wunderte sich diese.
              <p/>«Wir haben ein paar neue Mitarbeiter, der Pilatus-drache und der Teufel. Die machen immer Ärger, aber sie zu entlassen getraut sich niemand.»


              </Type>
        </Deutsch>

      </div>


      <img src={img3}/>






      <Back_To_All_Projects location={location} />


      </Page>
    </Layout>

)
